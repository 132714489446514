import './product.details.less'
import { asArray } from "../../general/helpers";
import { ID_PREFIX } from "../../constants";

angular.module('easybizy.products').controller('ProductDetailsController', function (
  $scope, $stateParams, Repository, $window, toolbar, localize, $timeout,
  $filter, configuration, $state, $q, confirmService, productsPickerRepository
) {
  toolbar.set([]);
  var configurations = configuration.get();
  $scope.useIngredients = !configuration.get().ProductsAndServicesSettings.IgnoreIngredients;
  $scope.useCompositeServices = configuration.get().ProductsAndServicesSettings.EnableCompositeService;
  $scope.usingInventory = !configurations.ProductsAndServicesSettings.IgnoreInventory;
  $scope.useSubModelType = configurations.ProductsAndServicesSettings.IncludeCustomModelCategory;
  $scope.pointsEnabled = configurations.PointsSettings && configurations.PointsSettings.EnablePoints && configurations.PurchaseTypeToInclude === "Custom";
  $scope.showWaitingAfter = configurations.ProductsAndServicesSettings.EnableWaitingAfter;
  $scope.usingBarcode = configurations.ProductsAndServicesSettings.UsingBarcode;
  $scope.hideCost = configurations.ProductsAndServicesSettings.HideCost;
  $scope.preventProductCreation = configurations.ProductsAndServicesSettings.PreventProductCreation;
  var tempServiceId = $stateParams.serviceId;
  let serviceDidHaveForm = false;
  var tempProductId = $stateParams.productId ? $stateParams.productId : $stateParams.ingredientId;
  var k_productDetailsType = "product";
  var k_serviceDetailsType = "service";
  var k_maxNumberOfCategories = 5;


  var entityId = tempServiceId ? tempServiceId : tempProductId;
  var type = $scope.type = tempServiceId ? k_serviceDetailsType : k_productDetailsType;
  var entityName = tempServiceId ? "ServiceMetadata" : "ProductMetadata";
  var expand = tempServiceId ? "Images, ServiceCategories, ProductCategories, ComposedServiceMetadatas, ComposedServiceMetadatas/ServiceMetadata" :
    "Images, Manufacturer, ProductCategories";

  $scope.productOrServiceDynamic = {};
  $scope.manufacturerWrapper = {};
  $scope.editMode = false;
  $scope.productOrServiceData = [];
  $scope.isLoadingProductDetails = {};
  $scope.isLoadingProductDetails.isLoading = true;
  $scope.isLoadingProductInfo = true;
  $scope.isSavingWrapper = {};
  $scope.isSavingWrapper.isSaving = false;
  $scope.isLoadingDataWrapper = {};
  $scope.imageIdFromAppToLink = 0;
  $scope.productFieldsToValidate = {}; // just initialize fields to validate.
  $scope.validateActionWrapper = {}; // this is an object to wrap an action, so the form validator can

  var viewLabels = {};
  $scope.viewLabels = viewLabels;

  var configuredCurrency = configuration.get().CashRegisterSettings.Currency.Value;
  var currencySymbol = "".toCurrencySymbol(null, configuredCurrency);

  function isCategoryAlreadyExist(existCategories, category) {
    var result = false;
    angular.forEach(existCategories, function (existCategory) {
      if (category.categoryValue === existCategory.value) {
        result = true;
        return false;
      }
    });
    return result;
  }

  $scope.imageUpdated = function (entity, image) {
    $scope.imageIdFromAppToLink = image.id;
  };

  $scope.selectForm = function () {
    if ($scope.productOrService && $scope.productOrService.FormId && $scope.possibleForms.length > 1) {
      $scope.selectedForm.valueWrapper =
        $scope.possibleForms.filter(x => x.value === $scope.productOrService.FormId)[0] || $scope.possibleForms[0];
    }
  }

  // New category selected regardless the entity type
  $scope.categorySelected = function (category) {
    if (type === k_productDetailsType && $scope.productCategories[0].value !== category.value) {
      if (!isCategoryAlreadyExist($scope.concreteProductCategories, category)) { // doesn't already exists.
        if ($scope.concreteProductCategories.length === k_maxNumberOfCategories) {
          toastr.warning(localize.getLocalizedString("_MaxCategoriesNumberIs_") + k_maxNumberOfCategories);
        } else {
          $scope.concreteProductCategories.push(category);
        }
      }
      $scope.productOrServiceDynamic.selectedProductCategories = $scope.productCategories[0];
    } else if (type === k_serviceDetailsType && $scope.serviceCategories[0].value !== category.value) {
      if (!isCategoryAlreadyExist($scope.concreteServiceCategories, category)) { // doesn't already exists.
        if ($scope.concreteServiceCategories.length === k_maxNumberOfCategories) {
          toastr.warning(localize.getLocalizedString("_MaxCategoriesNumberIs_") + k_maxNumberOfCategories);
        } else {
          $scope.concreteServiceCategories.push(category);
        }
      }
      $scope.productOrServiceDynamic.selectedServiceCategories = $scope.serviceCategories[0];
    }
  };


  // This part creates a proxy so the product categories which is common for both product and categories.
  $scope.productsProxy = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(false).entity("product");

  $scope.tileActionsResolver = {
    deleteEntity: function () {
      var deferred = $q.defer();
      var duplicatedEntity = {};
      duplicatedEntity[entityName + ID_PREFIX] = entityId;
      Repository.Entity(entityName).remove(duplicatedEntity)
        .then(function () {
          toastr.success(localize.getLocalizedString("_EntityRemovedSuccessfully_"));
          $state.go('Products');
          $scope.$digestIfNeeded();
          deferred.resolve(duplicatedEntity);
        })
        .catch(function () {
          toastr.error(localize.getLocalizedString("_ErrorRemovingEntity_"));
          deferred.reject(data);
        })
        .finally(deferred.finally);

      return deferred.promise;
    }
  };

  /* Product Part */
  if (type === "product") {
    viewLabels.firstInfoKey = localize.getLocalizedString("_QuantitySold_");
    viewLabels.secondInfoKey = localize.getLocalizedString("_TotalIncomes_");
    viewLabels.thirdInfoKey = localize.getLocalizedString("_AverageProfitPerProduct_");
    var quantityField = {
      mutualFieldName: 'Quantity', showLabel: true, fieldName: 'CurrentInventory',
      icon: "icon icon-inventory", placeholder: localize.getLocalizedString("_Quantity_"),
      validation: "{'required': true, 'decimal': true}"
    };

    var costField = {
      mutualFieldName: 'Cost', showLabel: true, fieldName: 'PurchasingPrice',
      icon: "icon icon-banknote-out", unitOfMeasure: currencySymbol,
      placeholder: localize.getLocalizedString("_PurchasePrice_"),
      validation: "{'required': true, 'decimal': true}"
    };

    var priceField = {
      mutualFieldName: 'Price', showLabel: true, fieldName: 'RetailPrice',
      icon: "icon icon-banknote-in", unitOfMeasure: currencySymbol,
      placeholder: localize.getLocalizedString("_RetailPrice_"),
      validation: "{'required': true, 'decimal': true}"
    };

    $scope.fields = [
      {
        mutualFieldName: 'Title',
        showLabel: true,
        fieldName: 'ProductLabel',
        icon: "icon icon-tag",
        placeholder: localize.getLocalizedString("_ProductName_"),
        validation: "{'required': true, 'max-length': 30}"
      }
    ];

    if (!$scope.hideCost) {
      $scope.fields.push(costField);
    }

    if ($scope.usingInventory) {
      $scope.fields.splice(1, 0, quantityField);
    }

    var barcodeField;
    if ($scope.usingBarcode) {
      barcodeField = {
        fieldName: 'SerialNumber', icon: 'icon icon-barcode',
        mutualFieldName: 'SerialNumber',
        placeholder: localize.getLocalizedString('_BarcodeNumber_'),
        validation: "{'required': false, 'max-length': 20}"
      };

      $scope.fields.push(barcodeField);
    }

    $scope.manufactors = [
      { name: localize.getLocalizedString("_SelectManufactor_"), value: null }
    ];
    $scope.productOrServiceDynamic.SelectedManufacturer = $scope.manufactors[0];

    $scope.manufacturersProxy = Repository.Entity("Manufacturer").query(function (x) {
      // decorate entity method.
      return { name: x.ManufacturerName, value: x.ManufacturerId };
    }, function (manufacturerResult) {
      // decorate list method.
      return manufacturerResult.results;
    });

    $scope.newManufacturers = [];
    $scope.addNewManufactorAction = function (newManufacturer) {
      $scope.newManufacturers.push({ name: newManufacturer.name, id: newManufacturer.value });
    };

    $scope.productCategories = [
      { name: localize.getLocalizedString("_AddProductCategory_"), value: null }
    ];
    $scope.productOrServiceDynamic.selectedProductCategories = $scope.productCategories[0];
    $scope.concreteProductCategories = [];

    // This part handles with the creation on new product categories
    $scope.newCategoriesToCreate = [];
    $scope.addNewCategoryAction = function (category) {
      $scope.newCategoriesToCreate.push({
        name: category.name,
        id: category.categoryValue,
        parentId: category.parentId
      });
    };


    $scope.removeProductCategory = function (category) {
      $scope.concreteProductCategories.remove(category);
    };
  }

  /* Service Part */
  if (type === "service") {
    viewLabels.firstInfoKey = localize.getLocalizedString("_ServicePerforemed_");
    viewLabels.secondInfoKey = localize.getLocalizedString("_ServiceRelativePart_");
    viewLabels.thirdInfoKey = localize.getLocalizedString("_AverageIngredientCost_");

    $scope.fields = [
      {
        mutualFieldName: 'Title',
        showLabel: true,
        fieldName: 'ServiceName',
        icon: "icon icon-tag",
        placeholder: localize.getLocalizedString("_ServiceName_"),
        validation: "{'required': true, 'max-length': 30}"
      },
      {
        mutualFieldName: 'Duration',
        showLabel: true,
        fieldName: 'Duration',
        icon: "icon icon-time",
        placeholder: localize.getLocalizedString("_Duration_"),
        validation: "{'required': true, 'max-length': 20}"
      },
      {
        mutualFieldName: 'Price',
        showLabel: true,
        fieldName: 'RetailPrice',
        icon: "icon icon-banknote-in",
        unitOfMeasure: currencySymbol,
        placeholder: localize.getLocalizedString("_RetailPrice_"),
        validation: "{'required': true, 'decimal': true}"
      },
      {
        fieldName: 'Color', icon: "icon icon-color", placeholder: localize.getLocalizedString("_ServiceColor_"),
        validation: "{'color': true }", type: 'color'
      }
    ];

    var waitingField;
    if ($scope.showWaitingAfter) {
      waitingField = {
        mutualFieldName: 'WaitingAfter',
        showLabel: true,
        fieldName: 'WaitingAfter',
        icon: "icon icon-break",
        placeholder: localize.getLocalizedString("_BreakAfter_"),
        validation: "{'required': true, 'max-length': 20}"
      };

      $scope.fields.splice(2, 0, waitingField);
    }

    $scope.serviceCategories = [
      { name: localize.getLocalizedString("_AddServiceCategory_"), value: null }
    ];
    $scope.productOrServiceDynamic.selectedServiceCategories = $scope.serviceCategories[0];
    $scope.servicesProxy = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(false).entity("service");

    $scope.concreteServiceCategories = [];
    $scope.removeServiceCategory = function (category) {
      $scope.concreteServiceCategories.remove(category);
    };
    // Showing and hiding the ingredients part.
    $scope.isIngredientsMode = false;
    $scope.toggleIngredientsMode = function () {
      $scope.isIngredientsMode = !$scope.isIngredientsMode;
    };

    /***** Form related to service *****/
    $scope.possibleForms = [{ name: localize.getLocalizedString('_PickForm_'), value: null }]
    $scope.selectedForm = { valueWrapper: $scope.possibleForms[0] };

    Repository.Custom('Forms').getForms().then((forms) => {
      forms.forEach(({ name, _id }) => $scope.possibleForms.push({ name, value: _id }));
      $scope.selectForm();
    }).catch(e => toastr.error(e.message));


    /* Service Ingredients. */
    $scope.ingredientCategories = [];
    $scope.removeIngredientCategory = function (category) {
      $scope.ingredientCategories.remove(category);
    };

    $scope.ingredients = [
      { name: localize.getLocalizedString("_AddIngredientCategory_"), value: null }
    ];
    $scope.selectedIngredientWrraper = {};
    $scope.selectedIngredientWrraper.selectedIngredient = $scope.ingredients[0];
    $scope.ingredientSelected = function (category) {
      if (!isCategoryAlreadyExist($scope.ingredientCategories, category)) { // doesn't already exists.
        if ($scope.ingredientCategories.length === k_maxNumberOfCategories) {
          toastr.warning(localize.getLocalizedString("_MaxCategoriesNumberIs_") + k_maxNumberOfCategories);
        } else {
          $scope.ingredientCategories.push(category);
        }
      }
      $scope.selectedIngredientWrraper.selectedIngredient = $scope.ingredients[0];
    };


    /* Composed Services. */
    $scope.composedServicesProxy = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(true).entity("service");
    $scope.composedServices = [];
    $scope.removeComposedService = function (service) {
      $scope.composedServices.remove(service);
    };

    $scope.services = [
      { name: localize.getLocalizedString("_AddComposedService_"), value: null }
    ];

    $scope.selectedComposedServicesWrapper = {};
    $scope.selectedComposedServicesWrapper.selectedComposedService = $scope.services[0];
    $scope.composedServiceSelected = function (composedService) {
      var copyOfComposedServices = Object.assign({}, composedService);
      delete copyOfComposedServices.$$hashKey;
      $scope.composedServices.push(copyOfComposedServices);

      $scope.selectedComposedServicesWrapper.selectedComposedService = $scope.ingredients[0];
    };


    // This part handles with the creation on new service categories
    $scope.newCategoriesToCreate = [];
    $scope.addNewCategoryAction = function (category) {
      $scope.newCategoriesToCreate.push({
        name: category.name,
        id: category.categoryValue,
        parentId: category.parentId
      });
    };
  }


  $scope.edit = function () {
    $scope.editMode = !$scope.editMode;
    if ($scope.editMode) {
      $scope.originalProuctOrService = $.extend({}, $scope.productOrService);
    }
  };

  $scope.cancel = function () {
    $.extend($scope.productOrService, $scope.originalProuctOrService);

    if ($scope.productOrServiceDynamic.SelectedManufacturer) {
      $scope.manufacturerWrapper.manufacturer = $scope.productOrServiceDynamic.SelectedManufacturer;
    }

    if ($scope.productOrServiceDynamic.ingredientCategories) {
      $scope.ingredientCategories.length = 0;
      $scope.ingredientCategories.pushAll($scope.productOrServiceDynamic.ingredientCategories);
    }

    if ($scope.productOrServiceDynamic.composedServices) {
      $scope.composedServices.length = 0;
      $scope.composedServices.pushAll($scope.productOrServiceDynamic.composedServices);
    }

    if ($scope.selectedForm.valueWrapper.value || $scope.productOrService.FormId) {
      $scope.selectedForm.valueWrapper =
        $scope.possibleForms.filter(x => x.value === $scope.productOrService.FormId)[0] || $scope.possibleForms[0];
    }

    if ($scope.productOrServiceDynamic.selectedCategories.length > 0) {
      clearCategories();
      angular.forEach($scope.productOrServiceDynamic.selectedCategories, function (category) {
        $scope.categorySelected(category);
      });
    }

    $scope.edit();
  };

  function clearCategories() {
    $scope.concreteProductCategories ? $scope.concreteProductCategories.length = 0 : 0;
    $scope.concreteServiceCategories ? $scope.concreteServiceCategories.length = 0 : 0;
  }

  function concreteSave(entityToSave) {
    Repository.Custom("EntitiesLazyRepository").update(entityToSave)
      .then(function (result) {
        productsPickerRepository.clearCache();
        $scope.productOrServiceDynamic.SelectedManufacturer = $scope.manufacturerWrapper.manufacturer;
        $scope.edit();
        $scope.isSavingWrapper.isSaving = false;
      })
      .catch(function (e) {
        $scope.isSavingWrapper.isSaving = false;
        toastr.error(localize.getLocalizedString("_ErrorSavingEntity_"));
      })
  }

  $scope.save = function () {
    if (validateEntity()) {
      $scope.isSavingWrapper.isSaving = true;
      var entityToSave = generateEntity();
      //if there is a new image from app
      if ($scope.imageIdFromAppToLink > 0) {
        entityToSave.ImageId = $scope.imageIdFromAppToLink;
      }

      if (entityToSave.FormId || serviceDidHaveForm) {
        confirmService.confirm(
          /*title*/localize.getLocalizedString('_UpdateServiceCategories_'),
          /*subtitle*/localize.getLocalizedString('_UpdateServiceCategoriesDescription_'),
          /*onOk*/function () {
            entityToSave.AssignFormToCategory = false;
            concreteSave(entityToSave);
          },
          /*On cancel*/
          function () {
            entityToSave.AssignFormToCategory = true;
            concreteSave(entityToSave)
          },
          localize.getLocalizedString('_JustForThisService_'),
          /*Ok text*/localize.getLocalizedString('_AllCategory_'));
      } else {
        concreteSave(entityToSave);
      }

    }
  };


  // backFilter
  $scope.leftSideBackFilterModel = {};
  $scope.leftSideBackFilterModel.filters = [
    { type: "backButton" }
  ];

  // filter products
  $scope.filtersModel = {};
  if (type === k_productDetailsType) {
    $scope.filtersModel.filters = [
      { type: "productDetailsFilter", allowMultiple: true }
    ];
  } else {
    $scope.filtersModel.filters = [
      { type: "simpleHeader", params: { header: localize.getLocalizedString("_ServiceHistory_") } }
    ];
  }


  $scope.filtersModel.filterStateChanged = function (newFiltersState) {
    $scope.productsDataProxy.filter(newFiltersState.productDetailsFilter);
  };

  $scope.productsDataProxy = Repository.Custom("ProductOrServiceDetails").data(entityId, type);

  Repository.Entity(entityName).query().id(entityId).expand(expand).get()
    .then(function (data) {
      if (angular.isDefined(data)) {
        querySucceeded(data);
      } else {
        queryFailed();
      }
    })
    .catch(queryFailed);

  function querySucceeded(data) {
    $scope.ingredientOnly = data.IngredientOnly;
    var updateProductOrService = function () {
      $scope.productOrServiceTileWrapper = [];
      $scope.productOrService = data;
      $scope.productOrService.FormId = !data.RelatedFormId ? (data.ServiceCategories ? data.ServiceCategories[0].RelatedFormId : null) : data.RelatedFormId;
      $scope.selectForm();
      serviceDidHaveForm = !!data.FormId;
      $scope.productOrService.alwaysExpanded = true;
      if (data.Manufacturer) {
        $scope.productOrServiceDynamic.SelectedManufacturer = {
          name: data.Manufacturer.ManufacturerName,
          value: data.ManufacturerId
        };
        $scope.manufacturerWrapper = {};
        $scope.manufacturerWrapper.manufacturer = $scope.productOrServiceDynamic.SelectedManufacturer;
      }

      $scope.productOrServiceDynamic.selectedCategories = [];
      if (type === k_productDetailsType && angular.isDefined(data.ProductCategories)) {
        if (!data.IngredientOnly || configurations.ProductsAndServicesSettings.ChargeIngredients) {
          $scope.fields.push(priceField);
        }

        angular.forEach(data.ProductCategories, function (category) {
          var clientCategory = { name: category.ProductCategoryName, value: category.ProductCategoryId };
          $scope.productOrServiceDynamic.selectedCategories.push(clientCategory);
          $scope.categorySelected(clientCategory);
        });
      }

      if (type === k_serviceDetailsType && angular.isDefined(data.ServiceCategories)) {
        angular.forEach(data.ServiceCategories, function (category) {
          var clientCategory = { name: category.ServiceCategoryName, value: category.ServiceCategoryId };
          $scope.productOrServiceDynamic.selectedCategories.push(clientCategory);
          $scope.categorySelected(clientCategory);
        });

        if (angular.isDefined(data.ProductCategories)) { // ingredients.
          $scope.productOrServiceDynamic.ingredientCategories = [];
          angular.forEach(data.ProductCategories, function (category) {
            var clientCategory = { name: category.ProductCategoryName, value: category.ProductCategoryId };
            $scope.productOrServiceDynamic.ingredientCategories.push(clientCategory);
            $scope.ingredientCategories.push(clientCategory);
          });
        }

        if (angular.isDefined(data.ComposedServiceMetadatas)) {
          $scope.productOrServiceDynamic.composedServices = [];
          angular.forEach(data.ComposedServiceMetadatas, function (composedService) {
            var composedService = {
              name: composedService.ServiceMetadata.ServiceName,
              value: composedService.ServiceMetadataId
            };
            $scope.productOrServiceDynamic.composedServices.push(composedService);
            $scope.composedServices.push(composedService);
          });
        }

        // DURATION
        var d = moment.duration($scope.productOrService.Duration);
        var duration = d.hours() + ":" + d.minutes();
        $scope.productOrService.Duration = duration;


        // TIME AFTER.
        d = moment.duration($scope.productOrService.WaitingAfter);
        duration = d.hours() + ":" + d.minutes();
        $scope.productOrService.WaitingAfter = duration;

      }

      if ($scope.ingredientOnly) {
        $scope.customModelTypes = [
          { name: localize.getLocalizedString("_WithoutExtraFormattedValue_"), value: null },
          { name: localize.getLocalizedString("_DecimalNumberFormat_"), value: 'decimal' }
        ];

        $scope.customModelTypeWrapper = {};
        $scope.customModelTypeWrapper.modelType = data.CustomModelFormat
          ? $scope.customModelTypes.filter(x => x.value === data.CustomModelFormat)
          : $scope.customModelTypes[0];
      }


      $scope.productOrServiceTileWrapper.push($scope.productOrService);
      $scope.imgUrl = $scope.productOrService.Images.length > 0 ? baseUrl + $scope.productOrService.Images[0].FilePath : null;
    };

    $scope.$applyIfNeeded(updateProductOrService);
    loadProductOrServiceInfo();
  }

  function loadProductOrServiceInfo() {
    $scope.isLoadingProductInfo = true;
    Repository.Custom("ProductOrServiceDetails").info(entityId, type)
      .then(function (data) {
        if (type === "product") {
          viewLabels.firstInfoValue = Math.floor(data.QuantitySold);
          viewLabels.secondInfoValue = Math.floor(data.TotalIncomes).toMoney();
          viewLabels.thirdInfoValue = roundTo2Digits(data.AverageProfitPerProduct) + "%";
        } else if (type === "service") {
          viewLabels.firstInfoValue = Math.floor(data.ServicesPerformed);
          viewLabels.secondInfoValue = roundTo2Digits(data.RelativePart) + "%";
          viewLabels.thirdInfoValue = roundTo2Digits(data.AverageIngredientCost).toMoney();
        }

        $scope.isLoadingProductInfo = false;
        // $scope.$digest();
      })
      .catch(function (err) {
        throw (err);
      });
  }

  function queryFailed(error) {
    toastr.error(localize.getLocalizedString("_EntityWasNotFound_"));
    $timeout(function () {
      $state.go('Products');
    }, 3000);
  }

  function roundTo2Digits(num) {
    return Math.round(num * 100) / 100;
  }

  function generateEntity() {
    return type === k_productDetailsType ? generateProduct() : generateService();
  }

  function validateEntity() {
    var validationFields = $scope.validateActionWrapper.validate();
    if (validationFields.length != 0) { // is valid form?
      var wrongFieldName = $scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
      var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
      toastr.error(localizedError);
      return;
    }

    return type === k_productDetailsType ? validateProduct() : validateService();
  }

  function generateBaseEntity() {
    var entity = {};
    angular.forEach($scope.fields, function (field) {
      entity[field.mutualFieldName] = $scope.productOrService[field.fieldName];
    });

    entity[entityName + ID_PREFIX] = $scope.productOrService[entityName + ID_PREFIX];
    entity.ConcreteType = entityName;
    return entity;
  }

  function validateService() {
    if ($scope.concreteServiceCategories.length === 0) {
      toastr.warning(localize.getLocalizedString("_AtLeastOneCategoryRequired_"));
      return false;
    }
    return true;
  }

  function generateService() {
    var service = generateBaseEntity();
    service.EntityType = "ServiceMetadata";
    service.Hidden = $scope.productOrService.Hidden;
    service.CategoriesToCreate = $scope.newCategoriesToCreate;
    var generateIdFromEntity = function (x) {
      var toReturn = {};
      if (angular.isDefined(x.categoryValue)) {
        toReturn.id = x.categoryValue;
      } else {
        toReturn.id = x.value;
      }
      return toReturn;

    };

    service.SelectedCategories = $scope.concreteServiceCategories.map(generateIdFromEntity);

    service.SelectedIngredientCategories = $scope.ingredientCategories.map(generateIdFromEntity);
    service.ComposedServiceMetadatas = $scope.composedServices.map(function (serviceMetadata) {
      return serviceMetadata.value
    });
    service.Color = $scope.productOrService.Color;

    if ($scope.selectedForm.valueWrapper.value) {
      service.FormId = $scope.selectedForm.valueWrapper.value;
    }

    return service;
  }

  function validateProduct() {
    if ($scope.concreteProductCategories.length === 0) {
      toastr.error(localize.getLocalizedString("_AtLeastOneCategoryRequired_"));
      return false;
    }
    return true;
  }

  function generateProduct() {
    var product = generateBaseEntity();
    product.EntityType = "ProductMetadata";
    product.Hidden = $scope.productOrService.Hidden;
    if (barcodeField) {
      product.SerialNumber = $scope.productOrService.SerialNumber;
    }

    product.SelectedManufacturerId = $scope.manufacturerWrapper.manufacturer.value;
    product.CategoriesToCreate = $scope.newCategoriesToCreate;
    product.ManufacturersToCreate = $scope.newManufacturers;
    product.SelectedCategories = asArray($scope.concreteProductCategories).map(x => ({
      id: angular.isDefined(x.categoryValue) ? x.categoryValue : x.value
    }));

    product.IngredientOnly = $scope.ingredientOnly;
    if ($scope.ingredientOnly && $scope.useSubModelType) {
      product.CustomModelFormat = $scope.customModelTypeWrapper.modelType.value;
    }

    return product;
  }
});
