import {ObservableProperties} from "../../../general/helpers";
import {
  CALENDAR_MINUTES_BETWEEN_MEETINGS_OPTIONS,
  DATE_DISPLAY_FORMAT,
  HOURS_AND_MINUTES_FORMAT
} from "../../../constants";
import {dateWithTime} from '@tomeravni/easybizy-js-common/date'
import {isNonEmptyArray} from '@tomeravni/easybizy-js-common/common'
import moment from "moment";
import {resolveCellHeightMode} from "./calendar.metadata.helper";
import {isNonZeroNumber} from "@tomeravni/easybizy-js-common/numbers";

(function () {
  angular.module('easybizy.calendar').service('calendarMetadata', [
    'configuration', 'Repository', 'stateManager', 'localize', 'colorsService', '$q', '$rootScope', '$timeout',
    function (configuration, Repository, stateManager, localize, colorsService, $q, $rootScope) {
      var formatKeys = this.formatKeys = {
        hourKey: "HH_mm",
        dayKey: "D_M",
        dayMonthYear: "YY_MM_DD",
        columnKey: "YYMMDD",
        fullDayFormat: 'ddd DD MMM YYYY',
        urlFormat: 'YY-MM-DD'
      };

      ObservableProperties(this);
      const persistInternalState = _persistInternalState.bind(this);
      const generateCalendarVisibleRange = _generateCalendarVisibleRange.bind(this);
      const validateDateVisibility = _validateDateVisiblity.bind(this);
      const setState = _setState.bind(this);
      const reloadEmployees = _reloadEmployees.bind(this);
      const setEmployeesOrder = _setEmployeesOrder.bind(this);

      stateManager.registerStateChanged('Calendar', setState);

      this.AWAITING_APPROVAL_STATUS = 'awaiting-approval';
      const CANCELLED_STATUS = this.CANCELLED_STATUS = 'cancelled';
      const INCLUDE_CANCELLED_STATUS = this.INCLUDE_CANCELLED_STATUS = 'include-cancelled';

      this.iterateDatePeriod = function iterateDatePeriod(startDateInput, endDate, callback) {
        var startDate = startDateInput.clone();
        while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
          callback(startDate, endDate);
          startDate.add(1, 'd');
        }
      };

      this.getPreviousState = function () {
        return stateManager.getPreviousState();
      };

      var settings = configuration.get();
      // Defaults view
      var defaultViewType = settings.CalendarSettings.DefaultCalendarView ? settings.CalendarSettings.DefaultCalendarView.Value : 'w';
      var enableRooms = settings.CalendarSettings.EnableRooms;
      var useRoomColors = settings.CalendarSettings.UseRoomColors;
      const resolveMinutesBetween = () => Math.round(settings.CalendarSettings.TimeSkipingIntervalInCalendar.Value * 60);

      var firstDayOfWork = -1;
      var lastDayOfWork;

      settings.CalendarSettings.DaysOfWork.forEach(function (dayOfWork) {
        if (dayOfWork.IsEnabled) {
          if (firstDayOfWork === -1) {
            firstDayOfWork = dayOfWork.Index;
          }

          lastDayOfWork = dayOfWork.Index;
        }
      });

      this.daysToKeepInMemory = {
        forward: 14,
        backward: -7
      };

      reloadEmployees();

      reloadRooms();

      function _setState(state, replaceExisting, previousState, viewChanged) {
        if (replaceExisting) {
          preventStatePersistence = true;
        }

        if (state.viewType) {
          this.viewType = state.viewType;
        } else {
          this.viewType = defaultViewType;
        }

        if (state.date && moment(state.date, formatKeys.urlFormat).isValid()) {
          this.setCustomDate(moment(state.date, formatKeys.urlFormat));
        } else {
          this.today();
        }

        if (state.employee) {
          var filterEmployeesBy = (function (employeeId) {
            var employee = this.employees.filter(function (employee) {
              return employeeId == employee.id;
            });

            if (employee.length > 0) {
              this.filteredEmployee = employee[0];
            }
          }).bind(this);

          if (this.employees) {
            filterEmployeesBy(state.employee);
          } else {
            this.getEmployeesAsync().then(function () {
              filterEmployeesBy(state.employee);
            })
          }
        } else {
          this.filteredEmployee = null;
        }

        // if (state.zoom) {
        //   const minutesBetweenMeetings = parseInt(state.zoom);
        //   if (isNonZeroNumber(minutesBetweenMeetings)) {
        //     const closest = CALENDAR_MINUTES_BETWEEN_MEETINGS_OPTIONS.reduce((prev, curr) =>
        //       Math.abs(curr - minutesBetweenMeetings) < Math.abs(prev - minutesBetweenMeetings) ? curr : prev
        //     );

        //     this.minutesBetweenMeetings = closest || this.minutesBetweenMeetings;
        //   }
        // }

        this.filteredStatus = state.status || null;
        this.stateMeeting = state.meeting || null;

        preventStatePersistence = false;
        persistInternalState(replaceExisting, null, viewChanged);
      }

      var currentViewType = defaultViewType;
      Object.defineProperty(this, 'viewType',
        (function () {
          return {
            get: function () {
              return currentViewType;
            },
            set: function (newVal) {
              if (newVal !== currentViewType) {
                currentViewType = newVal;
                persistInternalState(false, true);
              }
            }
          }

        }())
      );

      var currentStateMeeting = null;
      Object.defineProperty(this, 'stateMeeting',
        (function () {
          return {
            get: function () {
              return currentStateMeeting;
            },
            set: function (newVal) {
              if (newVal !== currentStateMeeting) {
                currentStateMeeting = newVal;
              }
            }
          }

        }())
      );

      Object.defineProperty(this, 'employeesInShift',
        (function () {
          let _employeesInShift;
          return {
            get: function () {
              return _employeesInShift;
            },
            set: function (newVal) {
              _employeesInShift = newVal;
            }
          }
        }())
      );

      var editMode = false;
      Object.defineProperty(this, 'editMode',
        (function () {
          return {
            get: function () {
              return editMode;
            },
            set: function (newVal) {
              if (newVal !== editMode) {
                editMode = !!newVal;
                persistInternalState(false, false, true);
              }
            }
          }

        }())
      );

      this.startingDay = firstDayOfWork;
      this.daysInWeek = lastDayOfWork - firstDayOfWork + 1;
      this.stepInMinutesBetweenMeetings = settings.CalendarSettings.MinimumMeetingLengthInMinutes.Value;
      this.minutesBetweenMeetings = resolveMinutesBetween();
      this.inlineEditShifts = settings.CalendarSettings.EnableInlineEditShifts;
      this.employee = null;
      this.showCustomerImagesOnMeetings = settings.CalendarSettings.ShowCustomerImagesOnMeetings;
      this.allowMeetingsOnTopOfExceptions = settings.CalendarSettings.AllowMeetingsOnTopOfExceptions;
      this.forceApprovalOnMeetingDrag = settings.CalendarSettings.ForceApprovalOnMeetingDrag;
      this.employeesOrder = null;

      this.meetingsInClipboard = [];

      this.updateMinutesBetweenMeetings = (minutesBetweenMeetings) => {
        this.minutesBetweenMeetings = minutesBetweenMeetings;
        ({cellHeightMode, configCellHeight} = resolveCellHeightMode(this.minutesBetweenMeetings));
        persistInternalState(false, true);
      }

      Object.defineProperty(this, 'serverMinutesBetweenMeetings', {
        get() {
          return resolveMinutesBetween();
        }
      })

      Object.defineProperty(this, 'startTime',
        (function () {
          var startTime = moment(settings.CalendarSettings.StartTime.Value, HOURS_AND_MINUTES_FORMAT);
          return {
            get: function () {
              return startTime.clone();
            }
          }

        }())
      );

      Object.defineProperty(this, 'endTime',
        (function () {
          var endTime = moment(settings.CalendarSettings.EndTime.Value, HOURS_AND_MINUTES_FORMAT);
          return {
            get: function () {
              return endTime.clone();
            }
          }

        }())
      );

      const DAY_PARTS_HOURS_MORNING = 11;
      const DAY_PARTS_HOURS_NOON = 16;

      /**
       * TODO: Daniel. Match day parts to online booking day parts.
       *
       */
      this.dayPartsMatcher = Object.freeze({
        morning: (dateTime) => {
          let todayStartTime = dateWithTime(dateTime, this.startTime);
          let maxMorning = moment(dateTime).hour(DAY_PARTS_HOURS_MORNING).minute(0).second(0).millisecond(0);
          if (maxMorning.isBefore(todayStartTime)) {
            maxMorning = todayStartTime;
          }

          return maxMorning.isAfter(dateTime);
        },
        noon: (dateTime) => {
          let todayStartTime = dateWithTime(dateTime, this.startTime);
          let todayEndTime = dateWithTime(dateTime, this.endTime);
          let minNoon = moment(dateTime).hour(DAY_PARTS_HOURS_MORNING).minute(0).second(0).millisecond(1);
          let maxNoon = moment(dateTime).hour(DAY_PARTS_HOURS_NOON).minute(0).second(0).millisecond(0);
          if (minNoon.isBefore(todayStartTime)) {
            minNoon = todayStartTime;
          }

          if (maxNoon.isAfter(todayEndTime)) {
            maxNoon = todayEndTime;
          }


          return minNoon.isBefore(dateTime) && maxNoon.isAfter(dateTime);
        },
        evening: (dateTime) => {
          let todayEndTime = dateWithTime(dateTime, this.endTime);
          let minEvening = moment(dateTime).hour(DAY_PARTS_HOURS_NOON).minute(0).second(0).millisecond(1);
          if (minEvening.isAfter(todayEndTime)) {
            minEvening = todayEndTime;
          }

          return minEvening.isBefore(dateTime);
        },
      })

      let {cellHeightMode, configCellHeight} = resolveCellHeightMode(this.minutesBetweenMeetings);
      //
      // let cellHeightMode = 'large-cells';
      // let configCellHeight = window.cellSizes.largeCellSize;
      // if (this.minutesBetweenMeetings <= 5) {
      //   cellHeightMode = 'extra-small-cells';
      //   configCellHeight = window.cellSizes.extraSmallCellSize;
      // } else if (this.minutesBetweenMeetings <= 15) {
      //   cellHeightMode = 'small-cells';
      //   configCellHeight = window.cellSizes.smallCellSize;
      // } else if (this.minutesBetweenMeetings <= 45) {
      //   cellHeightMode = 'medium-cells';
      //   configCellHeight = window.cellSizes.mediumCellSize;
      // }

      Object.defineProperty(this, 'cellHeightMode', {
        get() {
          return cellHeightMode;
        }
      });

      Object.defineProperty(this, 'actualCellHeight',
        (() => {
          let actualCellHeight = null;
          const defaultHeight = parseFloat(configCellHeight.replace('px', ''));

          return {
            get: () => {
              // 20/08/2022 - TOMER - Moved this logic to calendar drawers - calendar hoursDrawer.
              //
              //
              // if (!actualCellHeight) {
              //   const tmpHeight = this.calculateHeight();
              //   if (tmpHeight > 0.5 && Math.abs(tmpHeight - defaultHeight) < 1) {
              //     actualCellHeight = tmpHeight;
              //     $rootScope.$emit('calendar-cell-height-calculated', actualCellHeight);
              //
              //     $timeout(() => {
              //       const tmpHeight = this.calculateHeight();
              //       // This for fixing uncorrect height collection.
              //       if (tmpHeight !== actualCellHeight) {
              //         actualCellHeight = tmpHeight;
              //         console.log('Rendering was incorrect. rerenders.');
              //         this.notify('calendarStateChanged');
              //       }
              //
              //       console.log('height from metadata', tmpHeight);
              //
              //     }, 950)
              //   }
              // }

              return actualCellHeight || defaultHeight;
            },
            set(value) {
              actualCellHeight = value;
            }
          }

        })());

      var firstDayOfTheWeek = moment().startOf('day').weekday(this.startingDay);
      Object.defineProperty(this, 'firstDayOfTheWeek',
        (function () {
          return {
            get: function () {
              return firstDayOfTheWeek.clone();
            }
          }

        }())
      );

      var preventStatePersistence = false;

      function _persistInternalState(replaceLocation, clearMeeting, forceNotify) {
        if (preventStatePersistence) {
          return;
        }

        if (clearMeeting) {
          this.stateMeeting = null;
        }

        const stateToPersist = Object.assign({},
          stateManager.currentState('Calendar'), {
            viewType: this.viewType,
            employee: this.filteredEmployeeId,
            status: this.filteredStatus,
            date: currentDay.format(formatKeys.urlFormat),
            meeting: this.stateMeeting,
            // zoom: this.minutesBetweenMeetings
          });


        const stateChanged = stateManager.setState('Calendar', stateToPersist, false, replaceLocation);
        if (forceNotify || stateChanged) {
          generateCalendarVisibleRange();
          this.notify('calendarStateChanged');
        }
      }

      var currentRangeView = {label: '', datePicker: null};
      Object.defineProperty(this, 'currentRangeView',
        (function () {
          return {
            get: function () {
              return currentRangeView;
            }
          }

        }())
      );

      var dayString = localize.getLocalizedString('_Day_');

      function _generateCalendarVisibleRange() {
        delete currentRangeView.endDate;
        if (this.viewType === 'w') {
          // WEEKLY MODE
          var from = firstDayOfTheWeek.clone();
          var to = from.clone().add(this.daysInWeek - 1, 'd');

          // same month
          if (from.month() === to.month()) {
            currentRangeView.label = from.format("DD");
          } else if (from.year() === to.year()) {
            currentRangeView.label = from.format("DD MMM");
          } else {
            currentRangeView.label = from.format("DD MMM YYYY");
          }


          currentRangeView.label += " - " + to.format("DD MMM YYYY");
          currentRangeView.datePicker = this.firstDayOfTheWeek;
          currentRangeView.endDate = currentRangeView.datePicker.clone().add(1, 'week').format(DATE_DISPLAY_FORMAT);

          if (currentRangeView.datePicker.isSame(moment(), 'w')) {
            currentRangeView.datePicker = moment();
          }

          currentRangeView.datePicker = currentRangeView.datePicker.format(DATE_DISPLAY_FORMAT);
        } else {
          currentRangeView.label = dayString + currentDay.format(' dddd, DD MMM YYYY');
          currentRangeView.datePicker = currentDay.format(DATE_DISPLAY_FORMAT);
        }
      }

      var currentDay = moment().startOf('day'); // indicating today.
      this.getCurrentDay = function () {
        return currentDay.clone();
      };

      Object.defineProperty(this, 'isTodayVisible', {
        get: function () {
          return (this.viewType !== 'w' && currentDay.isSame(moment(), 'day')) ||
            (this.viewType === 'w' && currentDay.isSame(moment(), 'week'));

        }
      });

      var _employees;
      Object.defineProperty(this, 'employees', {
        get: function () {
          return _employees;
        }
      });

      this.getAvailableEmployeesForDate = (date = new Date()) => {
        const dayOfWeek = moment(date).day();
        return this.employees.filter((employee) => {
          return !isNonEmptyArray(employee.daysOfWork) || !!employee.daysOfWork[dayOfWeek]?.IsEnabled;
        });

      };

      var _rooms;
      Object.defineProperty(this, 'rooms', {
        get: function () {
          return _rooms;
        }
      });



      Object.defineProperty(this, 'enableRooms', {
        get: function () {
          return enableRooms;
        }
      });

      Object.defineProperty(this, 'useRoomColors', {
        get: function () {
          return useRoomColors;
        }
      });

      var _roomsColorById = {};
      this.getRoomNameById = function (roomId) {
        return _roomsColorById[roomId];
      };


      var filteredEmployee = null;
      Object.defineProperty(this, 'filteredEmployee', {
        get: function () {
          return filteredEmployee;
        },
        set: function (employee) {
          filteredEmployee = employee;

          if (employee) {
            persistInternalState();
          }
        }
      });

      let filteredStatusId = null;
      Object.defineProperty(this, 'filteredStatus', {
        get: function () {
          return filteredStatusId;
        },
        set: function (statusId) {
          const wasChanged = filteredStatusId !== statusId;
          const currentStatus = statusId;
          filteredStatusId = statusId;

          if (wasChanged) {
            /// If either new status is cancelled or old status is cancelled, reload and clear cache.
            if ([CANCELLED_STATUS, INCLUDE_CANCELLED_STATUS].some((validStatus) => [currentStatus, statusId].includes(validStatus))) {
              $rootScope.$emit('clearCalendarData');
            }

            persistInternalState();
          }
        }
      });

      Object.defineProperty(this, 'showCancelled', {
        get: function () {
          return [CANCELLED_STATUS, INCLUDE_CANCELLED_STATUS].includes(filteredStatusId);
        }
      });

      Object.defineProperty(this, 'filteredEmployeeId', {
        get: function () {
          return (this.filteredEmployee && this.filteredEmployee.id) || null;
        }
      });

      Object.defineProperty(this, 'daysOfWork', {
        get: function () {
          return settings.CalendarSettings.DaysOfWork;
        }
      });

      Object.defineProperty(this, 'isSingleWeeklyEmployeeMode', {
        get: function () {
          return !!(currentViewType === 'w' && (_employees.length === 1 || this.filteredEmployeeId));
        }
      });

      this.getEmployeesAsync = function (force) {
        var deferred = $q.defer();

        if (!force && _employees) {
          deferred.resolve(_employees);

        } else {
          reloadEmployees(deferred);
        }

        return deferred.promise;
      };

      this.shouldDateBeVisible = shouldDateBeVisible.bind(this);

      function shouldDateBeVisible(date) {
        const momentDate = moment(date);
        const isEnabled = settings.CalendarSettings.DaysOfWork[momentDate.day()].IsEnabled;

        return isEnabled;
      }

      this.prevPeriod = function () {
        if (this.viewType !== 'w') {
          currentDay.subtract(1, 'd');
          firstDayOfTheWeek = currentDay.clone().weekday(this.startingDay);
          validateDateVisibility(this.prevPeriod);
        } else {
          currentDay.add(-1, 'w');
          firstDayOfTheWeek.subtract(1, 'w');
        }

        persistInternalState(false, true);
      };

      this.nextPeriod = function () {
        if (this.viewType !== 'w') {
          currentDay.add(1, 'd');
          firstDayOfTheWeek = currentDay.clone().weekday(this.startingDay);
          validateDateVisibility(this.nextPeriod);
        } else {
          currentDay.add(1, 'w');
          firstDayOfTheWeek.add(1, 'w');
        }

        persistInternalState(false, true);

      };

      this.today = function () {
        firstDayOfTheWeek = moment().weekday(this.startingDay).startOf('day');
        currentDay = moment().startOf('day');
        persistInternalState(false, true);
      };

      this.setCustomDate = function (date) {
        if (date.isSameDate(currentDay)) {
          return;
        }

        currentDay = moment(date).startOf('day');
        firstDayOfTheWeek = currentDay.clone().weekday(this.startingDay);
        persistInternalState(false, true);
      };

      function _validateDateVisiblity(delegate) {
        if (!this.shouldDateBeVisible(currentDay)) {
          delegate.call(this);
        }
      }

      this.meetingStatuses = {
        'None': {
          name: localize.getLocalizedString('_Scheduled_'),
          value: 'None',
          colorClass: 'meeting-status-scheduled',
          icon: 'icon icon-meeting-status-scheduled'
        },
        'RemainderSent': {
          name: localize.getLocalizedString('_ReminderSent_'),
          value: 'RemainderSent',
          colorClass: 'meeting-status-reminder-sent',
          icon: 'icon icon-meeting-status-reminder-sent'
        },
        'Approved': {
          name: localize.getLocalizedString('_Approved_'),
          value: 'Approved',
          colorClass: 'meeting-status-approved',
          icon: 'icon icon-meeting-status-approved'
        },
        'Arrived': {
          name: localize.getLocalizedString('_Arrived_'),
          value: 'Arrived',
          colorClass: 'meeting-status-arrived',
          icon: 'icon icon-meeting-status-arrived'
        },
        'Paid': {
          name: localize.getLocalizedString('_Paid_'),
          value: 'Paid',
          colorClass: 'meeting-status-paid',
          icon: 'icon icon-meeting-status-paid'
        },
        'Other': {
          name: localize.getLocalizedString('_Other_'),
          value: 'Other',
          colorClass: 'meeting-status-other',
          icon: 'icon icon-meeting-status-other'
        }
      };

      function _reloadEmployees(promise) {
        Repository.Entity("Employee").query().filter('VisibleOnCalendar eq true').expand('DaysOfWork').get()
          .then(function (result) {
            var employees = [];
            angular.forEach(result.value, function (employee) {
              var parsedEmployee = {
                id: employee.EmployeeId,
                name: employee.FirstName + " " + employee.LastName,
                value: employee.EmployeeId,
                daysOfWork: employee.DaysOfWork,
                order: employee.Order
              };

              employees.push(parsedEmployee);
            });

            var sortedEmployees = employees.sort(function (employeeA, employeeB) {
              return (employeeA.order || employeeA.id) - (employeeB.order || employeeB.id);
            });

            setEmployeesOrder(sortedEmployees);

            _employees = sortedEmployees;
            if (promise) {
              promise.resolve(sortedEmployees);
            }
          })
          .catch(function (err) {
            if (promise) {
              promise.reject(err);
            }
          })
      }

      function _setEmployeesOrder(employees) {
        if (employees[0].order) {
          this.employeesOrder = employees.map(function (employee) {
            return employee.id;
          })
        }
      }

      function reloadRooms(promise) {
        Repository.Entity("Room").query().expand('ServiceMetadatas').get()
          .then(function (result) {
            var rooms = [];
            result.value.forEach(function (room) {
              var room = {
                id: room.RoomId,
                name: room.Name,
                color: room.Color,
                value: room.RoomId,
                serviceMetadatas: room.ServiceMetadatas.map(function (serviceMetadata) {
                  return serviceMetadata.ServiceMetadataId
                })
              };

              rooms.push(room);
              _roomsColorById[room.id] = colorsService.getColorByName(room.color) || 'barley-corn';
            });

            rooms.unshift({
              id: null,
              name: localize.getLocalizedString('_NoRoom_'),
              value: null,
              serviceMetadatas: null
            });

            _rooms = rooms;

            if (promise) {
              promise.resolve(_rooms);
            }
          })
          .catch(function (err) {
            if (promise) {
              promise.reject(err);
            }
          })
      }

    }]);

}());
