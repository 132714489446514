export const resolveCellHeightMode = (minutesBetweenMeetings) => {
  let cellHeightMode = 'large-cells';
  let configCellHeight = window.cellSizes.largeCellSize;
  if (minutesBetweenMeetings <= 5) {
    cellHeightMode = 'extra-small-cells';
    configCellHeight = window.cellSizes.extraSmallCellSize;
  } else if (minutesBetweenMeetings <= 15) {
    cellHeightMode = 'small-cells';
    configCellHeight = window.cellSizes.smallCellSize;
  } else if (minutesBetweenMeetings <= 45) {
    cellHeightMode = 'medium-cells';
    configCellHeight = window.cellSizes.mediumCellSize;
  }

  return { cellHeightMode, configCellHeight };
}
