<template>
  <div>
    <div class="flex items-center mb-4 justify-between">
      <div class="flex items-center">
        <label class="me-2">{{ $t('_View_') }}:</label>
        <ButtonsGroup :options="viewTypeOptions" v-model="viewType" :bordered="false" :spaceEvenly="true"
          class="evc-background--default me-4" />
      </div>
      <div class="flex items-center justify-start">
        <button class="flex items-center justify-start me-4" @click="mediator.print()">
          <div class="icon icon-print me-2"></div>
          <div>{{ $t('_Print_') }}</div>
        </button>
        <button class="flex items-center justify-start" @click="mediator.exportToExcel()">
          <div class="icon icon-excel me-2"></div>
          <div>{{ $t('_Export_') }}</div>
        </button>
      </div>
    </div>
    <Grid :dataSource="dataSource" v-bind="gridProps" :height="'70vh'"></Grid>
  </div>
</template>

<script>
import { ButtonsGroup, Grid, GridLabels } from "@tomeravni/easybizy-vue-components";
import { CustomersTableDS, AbandonedCustomersDS, CancelledCustomersDS, CustomersWithDebtDS,FirstMeetingCustomersDS } from "./customers.table.ds";
import Moment from 'moment';
import {resolveDefaultGridLabels} from "../../vue/vue.helpers";

export default {
  components: {
    Grid,
    ButtonsGroup,
  },
  props: {
    mediator: {
      type: Object
    }
  },
  data() {
    const labels = resolveDefaultGridLabels(this.$t);
    const viewType = 'default';
    const viewTypeOptions = [
      {
        text: this.$t('_Customers_'), value: 'default'
      },
      {
        text: this.$t('_AbandonedCustomers_'), value: 'abandonedCustomers'
      },
      {
        text: this.$t('_CancelledCustomers_'), value: 'cancelledCustomers'
      },
      {
        text: this.$t('_FirstMeetingCustomers_'), value: 'firstMeetingCustomers'
      },
      {
        text: this.$t('_CustomersWithDebts_'), value: 'customersWithDebts'
      }
    ];



    return {
      viewTypeOptions,
      viewType,
      dataSource: null,
      gridProps: {
        freezeHeader: true,
        hideBorder: false,
        elevated: true,
        pickColumns: true,
        rowHeightMode: 'tight',
        backgroundColor: 'auto',
        preventScrollTopButton: false,
        enableFilter: true,
        isFilterVisible: true,
        forceFullHeight: true,
        labels
      }
    }
  },

  mounted() {

  },
  methods: {
    updateDS(viewType) {
      let api;
      let dataSource;
      if (viewType === 'default') {
        api = this.Repository.Custom("QueryableTable").customers();
        api.pageSize = 100;
        dataSource = new CustomersTableDS(api, this.$t, this.$stateManager, this.mediator, this.Repository, this.printerMediator);
      } else if (viewType === 'abandonedCustomers') {
        api = this.Repository.Custom("QueryableTable").abandonedCustomers();
        api.pageSize = 100;
        dataSource = new AbandonedCustomersDS(api, this.$t, this.$stateManager, this.mediator, this.Repository, this.printerMediator);
        dataSource.filterValues = {
          LastMeeting: [Moment().startOf('year').add(-3, 'months').toDate(), new Date()]
        }
      } else if (viewType === 'cancelledCustomers') {
        api = this.Repository.Custom("QueryableTable").cancelledCustomers();
        api.pageSize = 100;
        dataSource = new CancelledCustomersDS(api, this.$t, this.$stateManager, this.mediator, this.Repository, this.printerMediator);
        dataSource.filterValues = {
          LastMeeting: [Moment().startOf('year').add(-3, 'months').toDate(), new Date()]
        }
      } else if (viewType === 'firstMeetingCustomers') {
        api = this.Repository.Custom("QueryableTable").firstMeetingCustomers();
        api.pageSize = 100;
        dataSource = new FirstMeetingCustomersDS(api, this.$t, this.$stateManager, this.mediator, this.Repository, this.printerMediator);
        dataSource.filterValues = {
          FirstMeeting: [Moment().startOf('year').add(-3, 'months').toDate(), new Date()]
        }
      } else if (viewType === 'customersWithDebts') {
        api = this.Repository.Custom("QueryableTable").customersWithDebt();
        api.pageSize = 100;
        dataSource = new CustomersWithDebtDS(api, this.$t, this.$stateManager, this.mediator, this.Repository, this.printerMediator);
      }

      this.dataSource = dataSource;
    }
  },
  watch: {
    viewType: {
      immediate: true,
      handler(viewType, prev) {
        this.updateDS(viewType);
      }
    },
  }

}
</script>
