import Sortable from 'sortablejs/Sortable';
import { HDate } from 'hebcal';
import { HOURS_AND_MINUTES_FORMAT } from "../../../constants";
import Tippy from 'tippy.js';

(function () {
  angular.module('easybizy.calendar').directive('hoursDrawer', [
    'calendarMetadata', 'calendarColumns',
    function (calendarMetadata, calendarColumns) {
      return {
        restrict: 'E',
        replace: true,
        link: function (scope, element) {
          render();

          calendarColumns.on('columnsChanged', (_, forceReload) => {
            if (forceReload) {
              render();
            }

          });


          function render() {
            var htmlToAppend = '<table>';
            var endTime = calendarMetadata.endTime;
            var startTime = calendarMetadata.startTime;
            while (startTime.isBefore(endTime)) {
              htmlToAppend += '<tr><td>\
                            <div class="calendar-cell-content-wrapper calendar-hour-cell">' +
                startTime.format('HH:mm') + '</div>\
                          </td></tr>';
              startTime.add(calendarMetadata.minutesBetweenMeetings, 'm');
            }

            htmlToAppend += '</table>';
            element.html(htmlToAppend);

            const placeholder = element.find('tr');
            let height = null;
            if (placeholder && placeholder.length > 3) {
              height = placeholder[0].getBoundingClientRect().height;
            }

            if (height <=0) {
              console.log('wrong height was set.', height);
            }

            calendarMetadata.actualCellHeight = height;
          }
        },
        template: '<div class="calendar-hours-wrapper"></div>'
      };
    }]);

  angular.module('easybizy.calendar').directive('daysHeaderDrawer', [
    'calendarColumns', 'configuration', 'calendarMetadata', '$rootScope', '$compile', 'localize', '$state', 'calendarStore',
    function (calendarColumns, configuration, calendarMetadata, $rootScope, $compile, localize, $state, calendarStore) {
      var settings = configuration.get();
      var showJewishDates = settings && settings.CalendarSettings && settings.CalendarSettings.ShowHebrewDates;

      return {
        restrict: 'E',
        replace: true,
        scope: {
          editMode: '='
        },

        link: function (scope, element) {
          calendarColumns.on('columnsChanged', render);
          scope.$watch('editMode', toggleDynamicBehavior);
          const employeesInShiftEvents = $rootScope.$on('employees-in-shift-calculated', function (_, employeesInShift) {
            drawInShiftEmployees(employeesInShift)
          });

          scope.$on('$destroy', () => {
            employeesInShiftEvents()
            onDestroy();
          });


          let isEmployeesView = false;

          /// Every render method, register new events. we want to cancel the old delegates.
          let cancelColumnsSpecificDelegates = () => {
          };


          function render(columns) {
            /// Cancel previous registered events.
            cancelColumnsSpecificDelegates();

            isEmployeesView = !!columns[0].employee;
            let htmlToAppend = '<table><tr>';
            const columnsDelegates = [];

            columns.forEach(function (col, index) {
              let customClass = getCustomClass(col);
              if (customClass.length > 0) {
                customClass = 'class="' + customClass + '" ';
              } else {
                customClass = '';
              }


              let holidayAndJewishDate = '';
              if (col.holiday || showJewishDates) {
                holidayAndJewishDate = '<div class="calendar-header-cell-holiday">';
                if (col.holiday) {
                  holidayAndJewishDate += '<div>' + col.holiday.label + '</div>';
                }

                if (showJewishDates) {
                  let currentDate = HDate(col.date.toDate());
                  holidayAndJewishDate += '<div>' + currentDate.toString('h') + '</div>'
                }

                holidayAndJewishDate += '</div>';
              }

              let employeeIdHtml = '';
              let linkToEmployee = '';
              if (col.employee) {
                employeeIdHtml = 'data-id="' + col.employee.id + '"';
                linkToEmployee = '&nbsp;<a href="/employees/' + col.employee.id + '" class="icon icon-link link-to-employee"></a>';
              }

              const renderAllDayMeetings = (allDayMeetings) => {
                const td = element.find(`tr td:nth-child(${ index + 1 })`)
                td.find('.all-day-meeting-container').remove()
                if (allDayMeetings.length > 0) {
                  let view = $(`<div class="all-day-meeting-container">
                              </div>`);

                  allDayMeetings.forEach(meeting => {
                    let meetingScope = scope.$new()
                    meetingScope.meeting = meeting;
                    let meetingView = $compile(`<div class="all-day-meeting ui-ellipsis">${ meeting.title }</div>`)(meetingScope);
                    meetingView.appendTo(view)
                  });


                  td.append(view)
                  td.find('.calendar-header-cell-wrapper').addClass('align-top')
                }
              }

              let getMeetingsDelegate = calendarStore.getMeetingsByDate(col, true).then(renderAllDayMeetings);
              /// Insert Delegate to list of delegates.
              columnsDelegates.push(() => getMeetingsDelegate && getMeetingsDelegate.cancel && getMeetingsDelegate.cancel());
              const meetingChangedDelegate = (event) => {
                const employeeId = col.employee && col.employee.id;
                if (event.dateTime === col.formattedDate && (!employeeId ||
                    (!event.meeting.originalMeeting.CompositeAdapters && event.meeting.originalMeeting.EmployeeId === employeeId))
                  && (!col.room || event.meeting.originalMeeting.RoomId === col.room.id)
                ) {

                  let meetingChangedCancelDelegate = calendarStore.getMeetingsByDate(col, true).then(renderAllDayMeetings);
                  columnsDelegates.push(() => meetingChangedCancelDelegate && meetingChangedCancelDelegate.cancel && meetingChangedCancelDelegate.cancel());
                }
              }

              calendarStore.on('meeting-changed', meetingChangedDelegate);
              columnsDelegates.push(() => calendarStore.off('meeting-changed', meetingChangedDelegate));


              htmlToAppend += `<td ${ customClass } ${ employeeIdHtml }>
                <div class="calendar-header-cell-wrapper ${ (col.holiday && showJewishDates ? 'extended-header-height' : '') }">
                  <div class="calendar-header-cell">${ col.label }${ linkToEmployee }</div>
                  <div>${ holidayAndJewishDate }</div>
                </div>
               </td>`;
            });


            htmlToAppend += '</tr></table>';
            element.html(htmlToAppend);
            if (scope.editMode) {
              setTimeout(toggleDynamicBehavior.bind(this, true))
            }

            drawInShiftEmployees(calendarMetadata.employeesInShift);
            /**
             * This method cancels current columns related events.
             */
            cancelColumnsSpecificDelegates = () => {
              columnsDelegates.forEach((cancelEv) => cancelEv());
              columnsDelegates.length = 0;
            };
          }


          function getCustomClass(col) {
            return (isToday(col.date) ? 'today-cell ' : '') + (col.holiday ? 'holiday-cell' : '');
          }

          function isToday(momentDate) {
            return moment().isSameDate(momentDate, 'day');
          }

          var sortable;

          function toggleDynamicBehavior(editMode) {
            if (!angular.isDefined(editMode)) {
              return;
            }

            if (editMode) {
              setTimeout(function () {
                var tds = element.find('tr td');
                if (tds.length > 1 && calendarMetadata.viewType !== 'w') {
                  var firstColumn = $(tds[0]);
                  firstColumn.addClass('animated swing');

                  setTimeout(function () {
                    firstColumn.removeClass('animated swing');
                  }, 1000)
                }
              });

              var row = element.find('tr')[0];
              sortable = new Sortable(row, {
                animation: 150,
                ghostClass: 'calendar-employee-drag-placeholder',
                onUpdate: function (/**Event*/evt) {
                  var animationType = 'animated flash';
                  var jqueryItem = $(evt.item);
                  setTimeout(function () {
                    jqueryItem.removeClass(animationType);
                  }, 1000);

                  jqueryItem.addClass(animationType);
                  var newOrder = [];
                  element.find('tr td').each(function () {
                    newOrder.push(parseInt(this.getAttribute('data-id')));
                  });

                  calendarColumns.employeesOrderChanged(newOrder);
                }
              });

            } else {
              sortable.destroy();
            }
          }

          function onDestroy() {
            cancelColumnsSpecificDelegates();
          }


          function drawInShiftEmployees(employeesInShift) {
            if (!employeesInShift || !isEmployeesView) {
              return;
            }

            employeesInShift = Object.values(employeesInShift);
            element.find('.calendar__employee-in-shift').remove();
            employeesInShift.forEach(employee => {
              const employeeHeaderElement = element.find(`[data-id='${ employee.EmployeeId }'] .calendar-header-cell-wrapper`);
              if (!employeeHeaderElement && !employeeHeaderElement[0]) {
                return;
              }

              const momentShift = employee.OpenCheckInTime;
              const adaptedStartingTime = momentShift.isSame(moment(), 'date') ? momentShift.format(HOURS_AND_MINUTES_FORMAT) : momentShift.format(`${ HOURS_AND_MINUTES_FORMAT } DD/MM`);
              const employeeMessage = localize.getLocalizedString('_EmployeeInShiftGoToEmployee_', adaptedStartingTime);
              // const inShiftItem = $compile(`<div class="vertical-center-absolute icon icon-clocking calendar__employee-in-shift"></div>`)(scope);
              const inShiftItem = $('<div class="vertical-center-absolute icon icon-clocking calendar__employee-in-shift"></div>');
              inShiftItem.on('click', () => {
                $state.go('EmployeeDetails', { employeeId: employee.EmployeeId });
              });

              employeeHeaderElement.append(inShiftItem);
              setTimeout(() => {
                Tippy(inShiftItem[0], {
                  content: employeeMessage,
                });

              })

            })


          }
        },
        template: '<div class="days-header-wrapper relative-container"></div>'
      };
    }]);


}());
