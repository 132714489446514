import angular from 'angular';
import uirouter from "@uirouter/angularjs";
import 'ngVue';
import 'ngVue/build/plugins.js'

export const MODULE_NAME = 'easybizy';
export const MAIN_MODULE = angular.module(MODULE_NAME,
  [
    /********** EXTERNALS DEPENDENCIES************/
    uirouter, 'ngAnimate', 'angular-cache', 'angularFileUpload', 'ngFileUpload', 'ui.bootstrap', 'pasvaz.bindonce', 'rzSlider', 'ngTagsInput', '720kb.tooltips',
    /********** OUR DEPENDENCIES ************/
    'easybizy.common.common-controls', 'easybizy.dashboard', 'easybizy.customers', 'easybizy.google-calendar',
    'easybizy.customerDetails', 'easybizy.calendar', 'easybizy.configurations', 'easybizy.promotions.widget', 'easybizy.promotions.manager',
    'easybizy.common.promotion.editors', 'easybizy.common.droppable.table', 'easybizy.reports', 'easybizy.common.custom.reports.actions',
    'easybizy.online.scheduling',
    'easybizy.leads', 'easybizy.products', 'easybizy.employees', 'easybizy.resources.management', 'easybizy.expenses', 'easybizy.cash.register', 'easybizy.vue', 'easybizy.forms', 'easybizy.conversations']);

export const SERVER_DATA_DATE_FORMAT = 'MM/DD/YYYY HH:mm';
export const RTL_DATE_TIME_DISPLAY = 'DD/MM/YYYY HH:mm';
export const HOURS_AND_MINUTES_FORMAT = 'HH:mm';
export const DATE_DISPLAY_FORMAT = 'DD/MM/YYYY';
export const UNIQUE_DATE_TIME = 'DDMMYYYYHHmmsss';
export const ODATA_DATE_ONLY_FORMAT = 'YYYY-MM-DDT12:00:00';
export const ODATA_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:00';
export const ALL_DAY_MEETING_FORMAT = 'YYYY-MM-DDT12:00:00';
export const CREDIT_CARD_EXPIRATION = 'MMYY';
export const SHORT_DATE_FORMAT = 'DD-MM-YY';
export const URL_FRIENDLY_SHORT_DATE_FORMAT = 'YY-MM-DD';
export const SESSION_RELATIVE_DATE_FORMAT = 'YYYY-MM-DD';
export const PATH_TO_API = 'api/';
export const ID_PREFIX = 'Id';

export const WHATSAPP_DEFAULT_TEMPLATES = Object.freeze({
  FORM: '__global_direct_form_v1'
});

export const CALENDAR_MINUTES_BETWEEN_MEETINGS_OPTIONS = [5,10,15,30,60]

export const MAX_CALENDAR_FIRST_LOAD_DELAY = 5000;
