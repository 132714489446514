import {isNonEmptyObject} from "@tomeravni/easybizy-js-common/common";
import {CALENDAR_MINUTES_BETWEEN_MEETINGS_OPTIONS} from "../../constants";

(function () {
  angular.module('easybizy.common.common-services').provider('stateManager', function stateManagerProvider() {
    var stateMachineLog = [{name: 'non', params: null}];
    var statesMetadata = {
      Calendar: {
        viewType: {
          type: 'string',
          value: null,
          dynamic: true,
          available: ['w', 'd', 'employees', 'rooms']
        },
        employee: {
          type: 'string',
          value: null,
          dynamic: true
        },
        date: {
          type: 'string',
          value: null,
          dynamic: true
        },
        status: {
          type: 'string',
          value: null,
          dynamic: true
        },
        customer: {
          type: 'string',
          value: null,
          dynamic: true
        },
        meeting: {
          type: 'string',
          value: null,
          dynamic: true
        },
        showCancelled: {
          type: 'string',
          value: null,
          dynamic: true
        },
        zoom: {
          type: 'int',
          value: null,
          dynamic: true,
          available: CALENDAR_MINUTES_BETWEEN_MEETINGS_OPTIONS
        }
      },
      Customers: {
        alphabetically: {
          type: 'string',
          value: null,
          dynamic: true
        },
        gender: {
          type: 'string',
          value: null,
          dynamic: true
        },
        search: {
          type: 'string',
          value: null,
          dynamic: true
        },
        tag: {
          type: 'string',
          value: null,
          dynamic: true
        },
        buckets: {
          type: 'string',
          value: null,
          dynamic: true
        },
        form: {
          type: 'string',
          value: null,
          dynamic: true,
        }
      },
      Employees: {
        shiftState: {
          type: 'string',
          value: null,
          dynamic: true
        },
        search: {
          type: 'string',
          value: null,
          dynamic: true
        },
      },
      CashRegister: {
        debt: {
          type: 'string',
          value: null,
          dynamic: true
        },
        sessionId: {
          type: 'string',
          value: null,
          dynamic: true
        },
        meetingId: {
          type: 'string',
          value: null,
          dynamic: true
        },
        customerId: {
          type: 'string',
          value: null,
          dynamic: true
        },
        defaultEmployeeId: {
          type: 'string',
          value: null,
          dynamic: true
        },
        relativeDate: {
          type: 'string',
          value: null,
          dynamic: true
        },
        priceQuoteId: {
          type: 'string',
          value: null,
          dynamic: true
        },
        duplicate: {
          type: 'string',
          value: null,
          dynamic: true
        }
      },
      Products: {
        viewType: {
          type: 'string',
          value: null,
          available: ['services', 'products', 'ingredients', 'externalvouchers', 'inventory', 'inventoryHistory', 'pricelists']
        },
        query: {
          type: 'string',
          value: null
        },
        sort: {
          type: 'string',
          value: null
        }
      },
      Leads: {
        leadName: {
          type: 'string',
          value: null,
          dynamic: true,
        },
        mobileFirst: {
          type: 'string',
          value: null,
          dynamic: true,
        },
        gender: {
          type: 'string',
          value: null,
          dynamic: true,
          available: ['male', 'female']
        },
        arrivalSource: {
          type: 'string',
          value: null,
          dynamic: true,
          delayedDelegation: true
        },
        createdOn: {
          type: 'string',
          value: null,
          dynamic: true
        },
        lastUpdated: {
          type: 'string',
          value: null,
          dynamic: true
        },
        nextHandlingTime: {
          type: 'string',
          value: null,
          dynamic: true
        },
        lastMeeting: {
          type: 'string',
          value: null,
          dynamic: true
        },
        nextMeeting: {
          type: 'string',
          value: null,
          dynamic: true
        },
        leadStatus: {
          type: 'string',
          value: null,
          dynamic: true
        },
        leadSubStatusId: {
          type: 'string',
          value: null,
          dynamic: true
        },
        arrivalSourceId: {
          type: 'string',
          value: null,
          dynamic: true
        },
        tags: {
          type: 'string',
          value: null,
          dynamic: true,
        },
        nextMeetingExist: {
          type: 'string',
          value: null,
          dynamic: true,
        },
        employee: {
          type: 'string',
          value: null,
          dynamic: true,
          delayedDelegation: true
        },
        visibleIndex: {
          type: 'string',
          dynamic: true,
          value: null
        }
      },
      ResourcesManagement: {
        viewType: {
          type: 'string',
          value: null,
          dynamic: true,
          delayedDelegation: true,
          force: true,
          available: ['employee-types', 'employee-assignments', 'rooms']
        }
      },
      OnlineScheduling: {
        viewType: {
          type: 'string',
          value: null,
          dynamic: true,
          delayedDelegation: true,
          force: true,
          available: ['availability', 'general', 'services']
        }
      },
      Forms: {
        viewType: {
          type: 'string',
          value: null,
          dynamic: true,
        },
        id: {
          type: 'string',
          value: null,
          dynamic: true,
        },
        mode: {
          type: 'string',
          value: null,
          dynamic: true,
        },
      },
      Conversations: {
        id: {
          type: 'string',
          value: null,
          dynamic: true,
        },
        filter: {
          type: 'string',
          value: null,
          dynamic: true,
        }
      },
      ManagePromotions: {
        promotionStatus: {
          type: 'string',
          value: null,
          dynamic: true,
          available: ['all', 'active', 'history']

        },
        promotionMedium: {
          type: 'string',
          value: null,
          dynamic: true,
          available: ['all', 'mail', 'sms', 'facebook', 'whatsapp']
        }
      }
    };

    var states = {};
    var delegates = {};
    this.getParamsForStateAsQueryParams = getParamsForStateAsQueryParams;
    this.getParamsForState = getParamsForState;

    function getParamsForStateAsQueryParams(stateName) {
      if (!statesMetadata.hasOwnProperty(stateName)) {
        return '';
      }

      return Object.keys(statesMetadata[stateName]).map(function (viewParamKey) {
        // const stateParamType = statesMetadata[stateName][viewParamKey].type;
        // Tomer Modified this, so it will work with integers as well. 12.02.2025, it is string regardless.
        return '{' + camelToDash(viewParamKey) + ':' + 'string' + '}';
      }).join('');
    }

    function getParamsForState(stateName) {
      var stateMetadata = {};
      Object.keys(statesMetadata[stateName]).forEach(function (viewParamKey) {
        stateMetadata[camelToDash(viewParamKey)] = statesMetadata[stateName][viewParamKey];
      });

      return stateMetadata;
    }

    this.$get =
      function stateManagerFactory($state, $transitions, $rootScope) {
        return new StateManager($state, $transitions, $rootScope);
      };

    function StateManager($state, $transitions, $rootScope) {
      this.getParamsForStateAsQueryParams = getParamsForStateAsQueryParams;
      this.getParamsForState = getParamsForState;
      this.getPreviousState = function () {
        return stateMachineLog[stateMachineLog.length - 1];
      };

      $transitions.onSuccess({}, stateTransitioned.bind(this));

      this.getParamValue = function (param) {
        return ($state.params && $state.params[param]);
      };

      $transitions.onExit({}, () => {
        $rootScope.$emit('page-in-progress', false);
      });


      function stateTransitioned(trans) {
        const to = trans.$to();
        const from = trans.$from();
        const isSameView = to.name === from.name;
        const params = Object.assign({}, trans.params());
        delete params['#'];
        stateChangeSuccess.call(this, to, params, isSameView);
      }

      function stateChangeSuccess(toState, toParams, isSameView) {
        var transformedParams = {};
        for (var param in toParams) {
          transformedParams[dashToCamel(param)] = toParams[param];
        }

        if (statesMetadata.hasOwnProperty(toState.name)) {
          var delayedDelegation = false;
          var validParams = {};
          for (var param in transformedParams) {
            delayedDelegation = delayedDelegation || statesMetadata[toState.name][param].delayedDelegation;
            if (isValidParamForState(toState.name, param, Object.assign({}, transformedParams))) {
              validParams[param] = transformedParams[param];
            } else if (statesMetadata[toState.name][param].force) {
              validParams[param] = statesMetadata[toState.name][param].available[0];
              return this.setState(toState.name, validParams, true, true);
            } else {
              validParams[param] = null;
            }
          }

          const forceChange = !isSameView || !states[toState.name] || JSON.stringify(validParams) !== JSON.stringify(states[toState.name]);

          var lastState = stateMachineLog[stateMachineLog.length - 1];
          states[toState.name] = validParams;
          var notifyDelegates = (function () {
            if (delegates[toState.name]) {
              delegates[toState.name].forEach(function (delegate) {
                delegate.call(this, validParams, true, lastState, forceChange);
              });
            }
          }).bind(this);

          if (lastState.name !== toState.name || JSON.stringify(lastState.params) !== JSON.stringify(validParams)) {
            stateMachineLog.push({name: toState.name, params: validParams});
          }


          if (!delayedDelegation) {
            return notifyDelegates();
          }

          angular.injector(["ng"]).invoke(function ($timeout) {
            $timeout(notifyDelegates);
          });
        }
      }

      this.setState = function (stateName, params, notify, replace) {
        if (JSON.stringify(params) === JSON.stringify(this.getValidRawParams())) {
          states[stateName] = params;
          return;
        }

        const stateChanged = JSON.stringify(params) !== JSON.stringify(states[stateName]);
        if (replace || stateChanged) {
          states[stateName] = params;
          var navigationParams = {notify: !!notify};
          if (replace) {
            navigationParams.location = 'replace';
          }

          $state.go(stateName, objectCamelToDash(params), navigationParams);
        }

        return stateChanged;
      };

      this.currentState = function (stateName) {
        return states[stateName];
      };

      this.getRawParams = () => objectDashToCamel($state.params);
      this.getValidRawParams = () => {
        const rawParams = this.getRawParams();
        return isNonEmptyObject(rawParams) ? Object.keys(JSON.parse(JSON.stringify(rawParams))).reduce((acc, curr) => {
          let toReturn = {...acc};
          if (rawParams[curr]) {
            toReturn[curr] = rawParams[curr];
          }

          return toReturn;
        }, {}) : {}
      }

      this.registerStateChanged = function (stateName, delegate) {
        if (!delegates.hasOwnProperty(stateName)) {
          delegates[stateName] = [];
        }

        var delegatesToRemove = [];
        for (var delegateIdx = 0; delegateIdx < delegates[stateName].length; delegateIdx++) {
          // Is it same function? ---> This handles the case of a controller registering the same function twice.
          if (delegates[stateName][delegateIdx].toString() === delegate.toString()) {
            delegatesToRemove.push(delegates[stateName][delegateIdx]);
          }
        }

        delegatesToRemove.forEach(function (delegate) {
          delegates[stateName].remove(delegate);
        });

        if (delegates[stateName].indexOf(delegate) < 0) {
          delegates[stateName].push(delegate);
        }

        return function () {
          delegates[stateName].remove(delegate)
        }
      };
    }

    function isValidParamForState(stateName, paramName, values) {
      const paramValue = values[paramName];
      const paramType = statesMetadata[stateName][paramName].type;
      const availabilities = statesMetadata[stateName][paramName].available;
      const splitValues = (paramValue && paramValue.hasOwnProperty('split') &&
        paramValue.split(',').map(x => x.trim())) || [paramValue];

      return statesMetadata[stateName].hasOwnProperty(paramName) &&
        ((!availabilities && isValueOfCertainType(paramType, paramName, values)) ||
          (availabilities &&
            (availabilities.indexOf(paramValue) >= 0 || (Array.isArray(splitValues) && splitValues.length > 0 && splitValues.length === availabilities.filter(x => splitValues.includes(x)).length))
          )
        );
    }

    function isValueOfCertainType(type, param, values) {
      if (!angular.isDefined(values)) {
        return false;
      }

      var toReturn = false;
      switch (type) {
        case 'string':
          toReturn = !!values[param];
          break;
        case 'int':
          toReturn = isNormalInteger(values[param]);
          break;
        case 'date':
          toReturn = angular.isDefined(values[param]) && moment(values[param], 'YY-MM-DD').isValid();
          break;
        case 'bool':
          toReturn = typeof (values[param]) === 'boolean';
          break;
      }

      return toReturn;
    }

    function isNormalInteger(str) {
      var n = ~~Number(str);
      return String(n) === str && n >= 0;
    }

    function camelToDash(string) {
      return string.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
    }

    function objectCamelToDash(obj) {
      var toReturn = {};
      for (var param in obj) {
        toReturn[camelToDash(param)] = obj[param] || null;
      }

      return toReturn;
    }

    function dashToCamel(string) {
      return string.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
      });
    }


    function objectDashToCamel(obj) {
      var toReturn = {};
      for (var param in obj) {
        toReturn[dashToCamel(param)] = obj[param] || null;
      }

      return toReturn;
    }


  });


  angular.module('easybizy.common.common-services').service('employees', function (configuration, Repository, localize, contextManager) {
    this.resolve = function (employees, selectedEmployeeWrapper, preventDefaultEmployee) {

      if (preventDefaultEmployee) {
        const pickEmployee = {name: localize.getLocalizedString('_PickEmployee_'), value: null}
        employees.push(pickEmployee);
        selectedEmployeeWrapper.selected = pickEmployee;
      }
      const defaultEmployee = configuration.defaultEmployee();
      if (angular.isObject(selectedEmployeeWrapper) && !preventDefaultEmployee) {
        selectedEmployeeWrapper.selected = defaultEmployee;
      }
      employees.push(defaultEmployee);

      Repository.Entity("Employee").query().get()
        .then(function (data) {
          data.results.forEach(parseEmployeeAndInsertEmployee.bind(employees));
          contextManager.get('employee').then(function (employee) {
            if (employee && angular.isObject(selectedEmployeeWrapper)) {
              var filteredEmployee = employees.filter(function (i_Employee) {
                return i_Employee.id === employee.EmployeeId;
              });

              if (filteredEmployee.length > 0) {
                selectedEmployeeWrapper.selected = filteredEmployee[0];
              }

            }
          });
        })
        .catch(function (/*e*/) {
          toastr.error(localize.getLocalizedString('_ErrorGettingEmployees_'));
        });
    };

    function parseEmployeeAndInsertEmployee(employee) {
      var employeeInside, defaultEmployee = configuration.defaultEmployee();

      if (employee.EmployeeId !== defaultEmployee.id) {
        employeeInside = {
          id: employee.EmployeeId,
          value: employee.EmployeeId,
          name: employee.FirstName + " " + employee.LastName
        };

        this.push(employeeInside);
      } else {
        employeeInside = defaultEmployee;
      }

      return employeeInside;
    }
  });

}());
